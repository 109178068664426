<template>
  <!-- 内容管理页面 -->
  <div class="positionT0L0" id="content_manage">
    <div v-show="$route.meta.isShow">
      <div class="top">
        <p>{{ name }}</p>
        <p>创建时间: {{ createTime }}</p>
      </div>
      <a-tabs :activeKey="activeKey" @change="callback">
        <a-tab-pane
          key="1"
          tab="章节管理"
          v-if="catalogueFlag == 1 && isShowCatalogueList"
        >
          <ChapterManage v-if="activeKey == 1" :courseNo="courseNo"/>
        </a-tab-pane>
        <a-tab-pane key="0" tab="课节管理" v-if="isShowCourseList">
          <LessonsManage
            v-if="activeKey == 0"
            :courseNo="courseNo"
            :catalogueFlag="catalogueFlag"
          />
        </a-tab-pane>
      </a-tabs>
    </div>
    <router-view />
  </div>
</template>

<script>
import ChapterManage from "./components/ChapterManage";
import LessonsManage from "./components/LessonsManage";
import { codeFn } from "@/utils/tools";
export default {
  created() {
    if (codeFn("/admin/course/catalogue/getCatalogueTreeResult")) {
      this.isShowCatalogueList = true;
    }
    if (codeFn("/admin/course/lessons/getCourseLessonsResult")) {
      this.isShowCourseList = true;
    }
    this.courseNo = localStorage.getItem("courseNo");
    this.createTime = localStorage.getItem("createTime");
    this.name = localStorage.getItem("name");
    this.catalogueFlag = localStorage.getItem("catalogueFlag");
    this.activeKey =
      localStorage.getItem("activeKey") && this.isShowCatalogueList
        ? localStorage.getItem("activeKey")
        : "0";
  },
  components: { ChapterManage, LessonsManage },
  data() {
    return {
      activeKey: null,
      catalogueFlag: null, // 是否分章节
      courseNo: "", // 课程编号
      name: "", // 课程名称
      createTime: "", // 创建时间
      isShowCatalogueList: false, // 章节列表是否隐藏
      isShowCourseList: false, // 课节列表是否隐藏
    };
  },
  methods: {
    // 点击切换tab栏
    callback(key) {
      this.activeKey = key;
      localStorage.setItem("activeKey", key);
    },
  },
  beforeDestroy() {
    localStorage.removeItem("catalogueFlag");
    localStorage.removeItem("activeKey");
    localStorage.removeItem("courseNo");
    localStorage.removeItem("createTime");
    localStorage.removeItem("name");
    localStorage.removeItem("courseType");
  },
};
</script>
 
<style lang="less" scoped>
.top {
  width: 100%;
  height: 80px;
  padding: 10px;
  font-size: 16px;
  background-color: #ecf8fe;
  p {
    margin-left: 20px;
    line-height: 30px;
    font-size: 14px;
    margin-bottom: 0 !important;
    &:nth-child(2) {
      color: #ccc;
    }
  }
}
.ant-tabs {
  margin-top: 20px;
}
</style>