<template>
    <!-- 章节管理组件 -->
    <div>
        <div class="list" v-for="(item, index) in catalogueArr" :key="item.id">
            <div class="big_list">
                <div class="list_left">
                    {{ index + 1 }}、<span>{{ item.title }}</span>
                </div>
                
                <div class="list_right">
                    <template v-show="isShowUpd">
                        <a-icon type="edit" class="my_icon" @click="editClick(index, item)" />
                    </template>
                    <a-icon v-show="isShowDel" type="delete" class="my_icon" @click="catalogueDeleteClick(item)" />
                </div>
            </div>

            <a-form class="col drag-form">
                <draggable v-model="item['lessonsNodes']" group="site" animation="300" scrollSensitivity="0px" dragClass="dragClass" ghostClass="ghostClass" @change="onChange(item)" chosenClass="chosenClass"
                    @start="onStart" @end="onEnd($event)">
                    <transition-group>
                        <div class="small_list" v-for="el in item.lessonsNodes" :key="el.lessonsId" @click="lessonsEditclick(el)">
                            <div class="small_list_left">{{ el.name }}</div>
                        </div>
                    </transition-group>
                </draggable>
            </a-form>
        </div>

        <div class="add_chapter" @click="addCatalogueClick" v-show="isShowAdd">
            <a-icon type="plus" class="add" />
            <span>新增章节</span>
        </div>

        <!-- 新增章节弹窗 -->
        <a-modal v-model="modelShow" title="添加章节" @ok="handleOk" @cancel="handleCancel">
            <span class="add_input">
                <a-input v-model="addaddCatalogueTitle" placeholder="章节名称" :maxLength="32" />
                <span>{{ addaddCatalogueTitle.length }}/32</span>
            </span>
            <UpdataImg @avatarfns="syllabusUrlFn" :imageUrlNum="1" :imgUrl="syllabus" style="margin-top: 20px"></UpdataImg>
        </a-modal>
    </div>
</template>

<script>
// npm i -S vuedraggable   拖拽组件
import draggable from "vuedraggable";
import UpdataImg from "@/components/file/updataImg";
import {
    CatalogueDeleteApi,
    CatalogueSortOperationApi,
    GetCourseLessonsDesResultApi,
    SaveCatalogueApi,
    UpdCatalogueApi,
    GetCatalogueTreeResultApi,
} from "@/request/api/coursManage";
import { message, Modal } from "ant-design-vue";
import { codeFn } from "@/utils/tools";
export default {
    props: ["courseNo"],
    created () {
        this.GetCatalogueTreeResultApiFn();
        if (codeFn("/admin/course/catalogue/saveCatalogue")) this.isShowAdd = true;
        if (codeFn("/admin/course/catalogue/updCatalogue")) this.isShowUpd = true;
        if (codeFn("/admin/course/catalogue/delete")) this.isShowDel = true;
    },
    data () {
        return {
            flag: 0,
            modelShow: false,
            syllabus: [],
            catalogueArr: [], // 章节的数组
            addaddCatalogueTitle: "", // 新增章节标题
            addCatalogueType: 1, // 新增章节类型
            drag: false, // 拖拽
            dragArr: [], // 拖拽的数组
            dragItem: {}, //
            title: "",
            isShowAdd: false, // 新增是否隐藏
            isShowUpd: false, // 修改是否隐藏
            isShowDel: false, // 删除是否隐藏
            id: '',
        };
    },
    components: { draggable,UpdataImg },
    methods: {
        syllabusUrlFn(data) {
            this.syllabus = data
        },
        // 拖拽开始
        onStart () {
            this.drag = true;
        },
        // 拖拽中
        onChange (data) {
            this.dragArr.push(data);
            if (this.dragArr.length === 1) {
                this.dragItem = this.dragArr[this.dragArr.length - 1];
            } else {
                this.dragItem = this.dragArr[this.dragArr.length - 2];
            }
        },
        // 拖拽结束
        onEnd (e) {
            this.$nextTick(() => {
                this.drag = false;
                if (this.dragItem.lessonsNodes) {
                    let arr = [];
                    this.dragItem.lessonsNodes.map((item, index) => {
                        arr.push({ id: item.lessonsId, sort: index });
                    });
                    CatalogueSortOperationApi({
                        catalogueNo: this.dragItem.catalogueNo,
                        lesssons: arr,
                    }).then(({ code, data }) => {
                        if (code == 200) {
                            message.success("排序成功", 1);
                        }
                    });
                }
            });
        },
        // 点击课节跳转到课节的编辑页面
        lessonsEditclick (subData) {
            let catalogueFlag = sessionStorage.getItem("catalogueFlag");
            let courseNo = localStorage.getItem("courseNo");
            GetCourseLessonsDesResultApi({ id: subData.lessonsId }).then(
                ({ code, data }) => {
                    if (code == 200) {
                        sessionStorage.setItem("editData", JSON.stringify(data));
                        this.$router.push(`/coursManage/courseList/contentManage/addLessons/${subData.lessonsId}/${courseNo}/${catalogueFlag}`);
                    }
                }
            );
        },
        // 新增章节按钮
        addCatalogueClick () {
            this.flag = 1
            this.modelShow = true
        },
        // 编辑按钮
        editClick (index, item) {
            this.flag = 0
            this.id = item.id
            this.addaddCatalogueTitle = item.title
            this.syllabus = item.backgroundImg.split(',')
            this.modelShow = true
        },
        // 添加章节确定按钮
        handleOk () {
            if (this.addaddCatalogueTitle) {
                if (this.flag === 1) {
                    SaveCatalogueApi({
                        courseNo: this.courseNo,
                        catalogueName: this.addaddCatalogueTitle,
                        backgroundImg: this.syllabus && this.syllabus.length > 0 ? this.syllabus.toString() : ''
                    }).then(({ code }) => {
                        if (code == 200) {
                            message.success("新增成功");
                            this.$router.go(0);
                        }
                    });
                } else {
                    UpdCatalogueApi({
                        courseNo: this.courseNo,
                        id: this.id,
                        catalogueName: this.addaddCatalogueTitle,
                        backgroundImg: this.syllabus && this.syllabus.length > 0 ? this.syllabus.toString() : ''
                    }).then(({ code }) => {
                        if (code == 200) {
                            message.success("修改成功");
                            this.$router.go(0);
                        }
                    });
                }
            } else {
                message.warning("请添加章节名称");
            }
        },

        handleCancel () {
            this.modelShow = false
            this.id = ''
            this.addaddCatalogueTitle = ''
            this.addCatalogueType = ''
            this.syllabus = []
        },
        // 章节目录删除按钮
        catalogueDeleteClick (arr) {
            let that = this;
            if (arr.lessonsNodes.length > 0) {
                Modal.confirm({
                    title: "提示",
                    content: "此章节下面有课节,不能删除",
                    okText: "确认",
                    okType: "danger",
                    cancelText: "取消",
                });
            } else {
                Modal.confirm({
                    title: "提示",
                    content: "删除后相关数据无法恢复, 是否确认删除",
                    okText: "确认",
                    okType: "danger",
                    cancelText: "取消",
                    onOk () {
                        CatalogueDeleteApi({ catalogueNo: arr.catalogueNo }).then(
                            ({ code, datea }) => {
                                if (code == 200) {
                                    message.success("删除目录成功");
                                    let catalogueArr = that.catalogueArr;
                                    that.catalogueArr = catalogueArr.filter(
                                        (item) => item.catalogueNo !== arr.catalogueNo
                                    );
                                }
                            }
                        );
                    },
                });
            }
        },
        GetCatalogueTreeResultApiFn () {
            GetCatalogueTreeResultApi({ courseNo: this.courseNo }).then(
                ({ code, data }) => {
                    if (code == 200) {
                        this.catalogueArr = data;
                    }
                }
            );
        },
    },
};
</script>

<style lang="less" scoped>
/*定义要拖拽元素的样式*/
.ghostClass {
    background-color: #f2f2f2 !important;
}
.chosenClass {
    background-color: #f2f2f2 !important;
    opacity: 1 !important;
}
.dragClass {
    background-color: #f2f2f2 !important;
    opacity: 1 !important;
    box-shadow: none !important;
    outline: none !important;
    background-image: none !important;
}
.col {
    flex: 1;
    border-radius: 5px;
    background: #fff;
}
.drag-form {
    /deep/ span {
        display: block;
        min-height: 1px;
    }
}
.my_icon {
    font-size: 26px;
    margin-right: 20px;
}

.list {
    margin-bottom: 10px;
    .big_list {
        width: 100%;
        height: 60px;
        line-height: 60px;
        background-color: #f2f2f2;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        border: 1px solid #cccccc59;
        .list_left {
            margin-left: 20px;
            min-width: 270px;

            .input {
                position: relative;

                span {
                    position: absolute;
                    top: -22px;
                    right: 6px;
                }
            }
        }
        .list_right{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
    .small_list {
        border: 1px solid #cccccc59;
        border-top: none;
        width: 100%;
        height: 50px;
        line-height: 50px;
        display: flex;
        justify-content: space-between;
        .small_list_left {
            margin-left: 20px;
        }
        .small_list_right {
            margin-right: 20px;
        }
    }
}

// 新增章节的输入框
.add_input {
    position: relative;
    span {
        position: absolute;
        top: 0;
        right: 6px;
    }
}
.input_box {
    width: 70%;
    height: 60px;
    line-height: 60px;
    background-color: #f2f2f2;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .ant-input {
        width: 400px;
        margin-left: 20px;
    }
}
// 新增章节
.add_chapter {
    width: 100%;
    height: 60px;
    line-height: 60px;
    background-color: #f2f2f2;
    padding-left: 20px;
    .add {
        font-size: 20px;
        color: #409eff;
        margin-right: 10px;
    }
}
</style>
