<template>
  <!-- 课节管理组件 -->
  <div>
    <!-- 顶部 -->
    <div class="top">
      <div class="top_left">
        <a-button icon="plus" type="primary" @click="addLessons" v-show="isShowAdd">
          添加课节
        </a-button>
      </div>
      <div class="top_rigiht">
        <span>课节老师</span>
        <a-select
          v-model="showTeach"
          style="width: 120px"
          :getPopupContainer="
            (triggerNode) => triggerNode.parentNode || document.body
          "
          @change="changeShowTeach"
        >
          <a-select-option v-for="item in showTeachArr" :key="item.labelNo">
            {{ item.labelText }}
          </a-select-option>
        </a-select>
        <span>课节状态</span>
        <a-select
          v-model="putawayFlag"
          style="width: 120px"
          :getPopupContainer="
            (triggerNode) => triggerNode.parentNode || document.body
          "
          @change="changePutawayFlag"
        >
          <a-select-option v-for="item in putawayFlagArr" :key="item.key">
            {{ item.value }}
          </a-select-option>
        </a-select>
        <a-input
          ref="userNameInput"
          v-model="searchVal"
          placeholder="请输入课节名称"
          style="width: 200px"
          @pressEnter="searchClick"
        >
          <a-icon slot="prefix" type="search" />
        </a-input>
        <a-button
          icon="search"
          type="primary"
          @click="searchClick"
          :disabled="isDisableSearch"
          >搜索</a-button
        >
      </div>
    </div>
    <!-- 表格 -->
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="false"
      :customRow="customRow"
      :loading="tableLoading"
    >
      <span slot="number" slot-scope="number, record, index">
        <span>{{ index + 1 }}</span>
      </span>
      <span slot="putawayFlagText" slot-scope="putawayFlagText, record">
        <a-badge
          v-show="record.putawayFlag == 0"
          status="error"
          text="已下架"
        />
        <a-badge
          v-show="record.putawayFlag == 1"
          status="processing"
          text="定时上架"
        />
        <a-badge
          v-show="record.putawayFlag == 2"
          status="success"
          text="已上架"
        />
        <a-badge
          v-show="record.putawayFlag == 3"
          status="warning"
          text="暂不上架"
        />
      </span>
      <span slot="operation" slot-scope="operation, record">
        <a v-show="isShowUpd" class="margin_right20" @click="editClick(record)"
          >编辑</a
        >
        <a
          v-show="record.putawayFlag == 2 && isShowOut"
          class="margin_right20"
          @click="TapeOutClick(record)"
          >下架</a
        >
        <a
          v-show="record.putawayFlag != 2 && isShowOnline"
          class="margin_right20"
          @click="TapeOutClick(record)"
          >上架</a
        >
        <a
          v-show="isShowCopy"
          class="margin_right20"
          @click="shareClick(record)"
          >复制链接</a
        >
        <a
          v-show="record.hkeyeFlag == 1 && isShowShow"
          class="margin_right20"
          @click="showOrHideClick(record)"
          >显示</a>
        <a
          v-show="record.hkeyeFlag == 0 && isShowHide"
          class="margin_right20"
          @click="showOrHideClick(record)">隐藏</a>
        <a v-show="isShowDel" class="margin_right20" @click="delClick(record)">删除</a>
        <a class="margin_right20" @click="updateLessonSortClick(record)">修改排序</a>
      </span>
    </a-table>
    <!-- 复制链接的弹框 -->
    <a-modal v-model="shareModalVisible" :footer="null" width="40%">
      <div class="share">分享</div>
      <a-tabs default-active-key="1">
        <a-tab-pane key="1" tab="H5">
          <div>
            <span style="margin-right: 20px">链接</span>
            <a-input
              placeholder="H5链接"
              disabled
              style="width: 80%"
              v-model="shareInputVal"
            />
            <a-button
              id="copy_text"
              :data-clipboard-text="shareInputVal"
              type="primary"
              style="margin-left: 20px"
              @click="handleCopyFun"
            >
              复制
            </a-button>
          </div>
        </a-tab-pane>
      </a-tabs>
    </a-modal>
    <!-- 修改排序的弹窗 -->
    <a-modal
      title="修改课节排序"
      width="400px"
      :visible="updateSortModalVisible"
      :confirm-loading="updateSortConfirmLoading"
      @ok="handleUpdateLessonSort"
      @cancel="closeSortModal"
    >
      <a-form
        :label-col="{span: 5}"
        :wrapper-col="{span: 12}"
      >
        <a-form-item label="课节排序：">
          <a-input-number
            v-model="currLessionSortData.sort"
            :min="0"
          />
        </a-form-item>
      </a-form>
    </a-modal>
    <!-- 分页 -->
    <MyPagination
      :count="count"
      :pageNo="pageNo"
      @showSizeChangeFn="showSizeChangeFn"
      v-show="!tableLoading"
    />
  </div>
</template>

<script>
import Clipboard from "clipboard";
import { message, Modal } from "ant-design-vue";
import {
  ShowTeacherApi,
  GetCourseLessonsResultApi,
  CopyCourseLessonsLinkApi,
  LessonsDeleteApi,
  CatalogueSortOperationApi,
  lessonsOnlineAdminV2Api,
  lessonsOutAdminV2Api,
  LessonsShowApi,
  LessonsHideApi,
} from "@/request/api/coursManage";
import MyPagination from "@/components/pagination/MyPagination";
import { codeFn } from "@/utils/tools";
export default {
  props: ["courseNo", "catalogueFlag"],
  components: { MyPagination },
  created() {
    this.ShowTeacherFn();
    let dictionaryObj = JSON.parse(localStorage.getItem("dictionaryObj"));
    dictionaryObj.putawayFlag.forEach((item) => this.putawayFlagArr.push(item));
    if (codeFn("/admin/course/lessons/saveLessons")) this.isShowAdd = true;
    if (codeFn("/admin/course/lessons/updLessons")) this.isShowUpd = true;
    if (codeFn("/admin/course/lessons/delete")) this.isShowDel = true;
    if (codeFn("/admin/course/lessons/onLine")) this.isShowOnline = true;
    if (codeFn("/admin/course/lessons/out")) this.isShowOut = true;
    if (codeFn("/admin/course/lessons/copyCourseLessonsLink")) {
      this.isShowCopy = true;
    }
    if (codeFn("/admin/course/lessons/show")) this.isShowShow = true;
    if (codeFn("/admin/course/lessons/hide")) this.isShowHide = true;
  },
  data() {
    return {
      pageNo: 1,
      pageSize: 20,
      type: 0, // 上下架传回后端的状态  状态[ 0：已下架 1：未上架 2：已上架]
      recordCurrent: {}, // 列表数据的当前项
      searchVal: "", // 搜索的值
      isDisableSearch: false, // 是否禁用搜索框
      showTeach: "", // 课节老师列表默认值
      showTeachArr: [{ labelNo: "", labelText: "全部老师" }], // 课节老师列表
      putawayFlag: "", // 课节状态的默认值
      // [0：已下架 1：定时上架 2：已上架[立即上架] 3：暂不上架]
      putawayFlagArr: [{ key: "", value: "全部状态" }], // 课节状态数组
      shareModalVisible: false, // 是否显示复制链接弹框
      shareInputVal: "", // 复制链接弹框的input值
      updateSortModalVisible: false, // 是否显示修改排序的弹窗
      updateSortConfirmLoading: false, // 修改排序弹窗的loading态
      currLessionSortData: {}, // 当前修改的课节排序数据
      count: 0, // 表格数组的总条数
      tableLoading: true, // 表格是否加载
      // 表格数据
      columns: [
        {
          title: "序号",
          dataIndex: "number",
          key: "number",
          width: "4%",
          scopedSlots: { customRender: "number" },
        },
        {
          title: "课节名称",
          dataIndex: "name",
          key: "name",
          width: "25%",
          scopedSlots: { customRender: "name" },
        },
        {
          title: "老师",
          dataIndex: "teacherName",
          key: "teacherName",
          width: "10%",
          scopedSlots: { customRender: "teacherName" },
        },
        {
          title: "类型",
          dataIndex: "lessonsText",
          key: "lessonsText",
          width: "10%",
          scopedSlots: { customRender: "lessonsText" },
        },
        {
          title: "状态",
          dataIndex: "putawayFlagText",
          width: "10%",
          key: "putawayFlagText",
          scopedSlots: { customRender: "putawayFlagText" },
        },
        {
          title: "创建时间",
          dataIndex: "createTime",
          width: "12%",
          sorter: true,
          key: "createTime",
          sorter: (a, b) => {
            let aTime = new Date(a.createTime).getTime();
            let bTime = new Date(b.createTime).getTime();
            return aTime - bTime;
          },
        },
        {
          title: "操作",
          key: "operation",
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
      tableData: [],
      sourceObj: {}, // 表格源对象
      sourceIndex: null, // 表格源对象索引
      targetObj: {}, // 表格目标对象
      targetIndex: null, // 表格目标对象索引
      isShowAdd: false, // 新增是否隐藏
      isShowUpd: false, // 新增是否隐藏
      isShowDel: false, // 删除是否隐藏
      isShowOnline: false, // 上架是否隐藏
      isShowOut: false, // 下架是否隐藏
      isShowCopy: false, // 复制链接是否隐藏
      isShowShow: false, // 显示是否隐藏
      isShowHide: false, // 隐藏是否隐藏
    };
  },
  watch: {
    $route: {
      handler(to) {
        if (to.name == "contentManage") {
          this.GetCourseLessonsResultFn();
        }
      },
      immediate: true,
    },
    searchVal(newVal) {
      if (newVal.length <= 0) {
        this.GetCourseLessonsResultFn();
      }
    },
    catalogueFlag(newVal) {
      if (newVal == "0") {
        this.GetCourseLessonsResultFn();
      }
    },
  },
  methods: {
    // 增加课节按钮
    addLessons() {
      this.$router.push(
        `/coursManage/courseList/contentManage/addLessons/0/${this.courseNo}/${this.catalogueFlag}`
      );
    },
    // 改变课节老师下拉框回调
    changeShowTeach(value) {
      this.showTeach = value;
      this.GetCourseLessonsResultFn();
    },
    // 改变课节老师的回调
    changePutawayFlag(value) {
      this.putawayFlag = value;
      this.GetCourseLessonsResultFn();
    },
    //   搜索的按钮
    searchClick() {
      this.isDisableSearch = true;
      setTimeout(() => {
        this.isDisableSearch = false;
      }, 3000);
      this.pageNo = 1;
      this.GetCourseLessonsResultFn();
    },
    // 编辑的按钮
    editClick(record) {
      location.href = `/coursManage/courseList/contentManage/addLessons/${record.key}/${record.courseNo}/${this.catalogueFlag}`;
    },
    // 上下架按钮
    TapeOutClick(record) {
      if (record.putawayFlag == 2) {
        Modal.confirm({
          title: "提示",
          content: "下架后用户无法在APP查看学习是否确定下架",
          okText: "确认",
          okType: "danger",
          cancelText: "取消",
          onOk() {
            lessonsOutAdminV2Api({ id: record.key }).then(({ code }) => {
              if (code == 200) {
                message.success("下架成功", 1);
                record.putawayFlag = 0;
              }
            });
          },
        });
      } else {
        lessonsOnlineAdminV2Api({ id: record.key }).then(({ code }) => {
          if (code == 200) {
            message.success("上架成功", 1);
            record.putawayFlag = 2;
          }
        });
      }
    },
    // 复制链接按钮
    shareClick(record) {
      this.shareModalVisible = true;
      CopyCourseLessonsLinkApi({
        courseNo: record.courseNo,
        lessonsNo: record.lessonsNo,
        lessonsType: record.lessonsType,
      }).then(({ code, data }) => {
        if (code == 200) {
          this.shareInputVal = data.lessonsLink;
        }
      });
    },
    // 分享弹框复制按钮
    handleCopyFun() {
      let clipboard = new Clipboard("#copy_text");
      clipboard.on("success", (e) => {
        message.success("复制成功");
        this.shareModalVisible = false;
        clipboard.destroy(); // 释放内存
      });
      clipboard.on("error", (e) => {
        message.error("复制失败"); // 不支持复制
        this.shareModalVisible = false;
        clipboard.destroy(); // 释放内存
      });
    },
    // 显示或者隐藏按钮
    showOrHideClick(record) {
      if (record.hkeyeFlag == 0) {
        LessonsHideApi({ lessonsNo: record.lessonsNo }).then(({ code }) => {
          if (code == 200) {
            message.success("隐藏成功", 1);
            record.hkeyeFlag = 1;
          }
        });
      } else {
        LessonsShowApi({ lessonsNo: record.lessonsNo }).then(({ code }) => {
          if (code == 200) {
            message.success("显示成功", 1);
            record.hkeyeFlag = 0;
          }
        });
      }
    },
    // 删除的按钮
    delClick(record) {
      let that = this;
      if (record.putawayFlag == 2) {
        Modal.confirm({
          title: "提示",
          content: "上架中的课节不能删除,请先下架课节",
          okText: "确认",
          okType: "danger",
          cancelText: "取消",
        });
      } else {
        Modal.confirm({
          title: "提示",
          content: "删除后相关数据无法恢复, 是否确认删除",
          okText: "确认",
          okType: "danger",
          cancelText: "取消",
          onOk() {
            LessonsDeleteApi({ id: record.key }).then(({ code, data }) => {
              if (code == 200) {
                message.success("删除成功");
                let tableData = that.tableData;
                that.tableData = tableData.filter(
                  (item) => item.key !== record.key
                );
              }
            });
          },
        });
      }
    },
    // 修改排序按钮事件
    updateLessonSortClick(record) {
      this.updateSortModalVisible = true;
      const {key, catalogueNo, sort} = record;
      this.currLessionSortData = {
        key,
        catalogueNo,
        sort
      };
    },
    handleUpdateLessonSort() {
      const data = this.currLessionSortData;
      const arr = [{
        id: data.key,
        sort: data.sort
      }];
      this.updateSortConfirmLoading = true;
      CatalogueSortOperationApi({
        catalogueNo: data.catalogueNo,
        lesssons: arr,
      }).then(({ code }) => {
        if (code == 200) {
          this.updateSortConfirmLoading = false;
          this.closeSortModal();
          message.success("修改排序成功", 1);
          this.GetCourseLessonsResultFn();
        }
      });
    },
    closeSortModal() {
      this.updateSortModalVisible = false;
    },
    // 分页功能切换的回调
    showSizeChangeFn(current, pageSize) {
      this.pageNo = current;
      this.pageSize = pageSize;
      this.GetCourseLessonsResultFn();
    },
    // 获取课节列表的函数
    GetCourseLessonsResultFn() {
      GetCourseLessonsResultApi({
        courseNo: this.courseNo,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        teacherNo: this.showTeach, //老师编号
        putawayFlag: this.putawayFlag, //上架状态：[0：已下架 1：定时上架 2：已上架[立即上架] 3：暂不上架]
        name: this.searchVal, // 课节名称
      }).then(({ code, data }) => {
        if (code == 200) {
          this.tableLoading = false;
          let newData = data.data.map((item) => {
            return JSON.parse(JSON.stringify(item).replace(/id/g, "key"));
          });
          this.tableData = newData;
          this.count = data.count;
        }
      });
    },
    // 获取老师列表函数
    ShowTeacherFn() {
      ShowTeacherApi().then(({ code, data }) => {
        if (code == 200) {
          this.showTeachArr.push(...data);
        }
      });
    },
    // 表格拖动
    customRow(record, index) {
      if (this.catalogueFlag == "0") {
        return {
          props: {
            // draggable: 'true'
          },
          style: {
            cursor: "pointer",
          },
          on: {
            // 鼠标移入
            mouseenter: (event) => {
              var ev = event || window.event; // 兼容IE
              ev.target.draggable = true;
            }, // 开始拖拽
            dragstart: (event) => {
              var ev = event || window.event; // 阻止冒泡    // 兼容IE
              ev.stopPropagation(); // 得到源目标数据
              this.sourceObj = record;
              this.sourceIndex = index;
            }, // 拖动元素经过的元素
            dragover: (event) => {
              var ev = event || window.event; // 阻止默认行为     // 兼容 IE
              ev.preventDefault();
            }, // 鼠标松开
            drop: (event) => {
              // 兼容IE
              var ev = event || window.event; // 阻止冒泡
              ev.stopPropagation(); // 得到目标数据
              this.targetObj = record;
              this.targetIndex = index;
              this.tableData.splice(this.sourceIndex, 1);
              this.tableData.splice(this.targetIndex, 0, this.sourceObj);
              let arr = [];
              this.tableData.map((item, index) => {
                const newIdx = (this.pageNo - 1) * this.pageSize + index;
                arr.push({ id: item.key, sort: newIdx });
              });
              CatalogueSortOperationApi({
                catalogueNo: record.catalogueNo,
                lesssons: arr,
              }).then(({ code }) => {
                if (code == 200) {
                  message.success("排序成功", 1);
                }
              });
            },
          },
        };
      }
    },
  },
};
</script>
 
<style lang="less" scoped>
.top {
  display: flex;
  justify-content: space-between;
  .top_rigiht {
    span {
      margin-right: 10px;
    }
  }
  .ant-select {
    margin-right: 10px;
  }
}
// 弹框
.tips {
  text-align: center;
  margin: 20px 0;
  font-size: 20px;
  font-weight: 600;
  .anticon {
    font-size: 40px;
    margin-right: 20px;
    color: #ff0000;
  }
}
.text {
  margin-top: 40px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}
.del_btn {
  margin-top: 60px;
  display: flex;
  justify-content: center;
  .ant-btn {
    margin-left: 20px;
  }
}
.share {
  width: 95%;
  text-align: center;
  height: 30px;
  line-height: 30px;
  background-color: #efefef;
}
/deep/.ant-select-dropdown-placement-bottomLeft {
  top: 96px !important;
}
</style>